import { OpenAISearchResults } from "./OpenAISearchResults.client"
import { SUResults } from "./SUResults.client"
import { SearchHelp } from "./SearchHelp.client"

export type searchType = 'search' | 'RAG' | 'aisearch'

export const SearchResults = ({searchType = 'RAG'}: {
  searchType?: searchType
}) => {

  return <>
    {searchType == 'search' && (
        <SUResults />
    )}

    {(searchType == 'RAG' || searchType == 'aisearch') && (
      <>
        <OpenAISearchResults type={searchType} />
      </>
    )}
  </>
}